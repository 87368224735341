import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Typography } from '@mui/material'
import './style.scss'
import CloseIcon from '@mui/icons-material/Close';


const SubStop = ({address, count, color,index,  removeStop, stopNote}) => {


  return (
    <div style={{ display: 'flex' ,flexDirection:'row' , alignItems:'center',gap: 1,paddingLeft:25}}>
        <span>
            <LocationOnIcon  style={{ width: 20, height: 20, color: '#FF7A00' }} />
            <div style={{ fontSize: 8, width: 10, height: 10, borderRadius: 5, backgroundColor: color, color: '#fff', display:'flex', justifyContent: 'center', alignItems: 'center',top: -25, right: -10, position: 'relative'  }}>{count}</div>
        </span>
        <Typography fontSize={12} marginBottom={1}  className="text" style={{marginLeft: 5,wordBreak: "break-word",}}  >{address}</Typography>
        {removeStop && <span>
            <CloseIcon  style={{ width: 20, height: 20, color: 'red', marginLeft: 10, cursor: 'pointer' }} onClick={()=> removeStop(index)} />
        </span>}
    </div>
  )
}

export default SubStop    