import React, { useEffect } from 'react'
import './login.scss'
import { Grid, Typography, Button, Box, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { Link, useNavigate, useNavigation } from "react-router-dom";
import GoogleImg from '../../assets/images/google.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { googleSignIn, loginUsers } from '../../Redux/actions/userActions'
import { toast } from 'react-toastify'
import { RESET_ERROR } from '../../Redux/constants/userConstants'
import { useGoogleLogin } from '@react-oauth/google';
import HomeIcon from '@mui/icons-material/Home';
import HomeTopBar from '../../components/HomeTopBar';
import Footer from '../../components/Footer';
import Banner from '../../components/Banner';
import BorderBox from '../../components/BorderBox';
import axios from 'axios'
import { display } from '@mui/system';
import Avatar from '@mui/material/Avatar/Avatar';
import logo from '../../assets/images/citilux_logo.png'
import Layout from '../Layout';


const Login = () => {

	const dispatch = useDispatch();



	const login = useGoogleLogin({
		onSuccess: tokenResponse => {
			getUserProfile(tokenResponse.access_token)
		},
	});


	const getUserProfile = async (token) => {
		await axios
			.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json'
				}
			})
			.then((res) => {
				//setProfile(res.data);


				let data = {
					email: res?.data?.email,
					name: res?.data?.name
				}
				localStorage.setItem("loginType", "google");
				dispatch(googleSignIn(data))

			})
			.catch((err) => toast.error({ err }));
	}

	const { loading, isAuthenticated, error } = useSelector(state => state.auth)

	const schema = yup.object({
		email: yup.string().email('Invalid email format').required('Email required').max(30, 'Maximum character exceeded'),
		password: yup.string()
			.required('No password provided.')
			.min(8, 'Password should contain at least 8 characters').max(30, "Maximum character exceeded")
	}).required();

	const navigate = useNavigate();
	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode: 'onChange'
	});

	useEffect(() => {
		if (error) {
			toast.error(error)
			dispatch({
				type: RESET_ERROR
			})
		}
		if (isAuthenticated) {
			navigate("/dashboard")
		}
	}, [error, isAuthenticated])


	const onSubmit = data => {
		localStorage.setItem("loginType", "form");
		dispatch(loginUsers(data))
	};



	return (
		<div className='login-holder'>
		<Layout head={""}>
			<div className='log-bg'>
				<Grid className='padd-r-l'>
					<Grid className='log-d-f' container>
						<Grid item xs={12} md={6} sx={{}} sm={12} className='log-text'>
							<h3>Please <span className='log-left'>Login</span> To Your Account</h3>
							<Grid item xs={12} md={6} marginTop={{ xs:"20px", md:"0" }} className='login_rightsec' style={{maxWidth:"95%", width:"100%", paddingTop:"10px"}}>
								{/* <Button variant="contained" onClick={() => login()} className="btn_google_login" style={{}}
									startIcon={<img src={GoogleImg} style={{ width: 22, height: 22, margin: 0, paddingLeft: 2 }} />}>
									<Typography className='buttonText'> Google Sign In</Typography>
								</Button> */}
								<Grid className='reg-link' px={3} style={{ textAlign: 'left' }}>
									<span style={{ fontFamily: 'Nunito', fontSize: 15, marginRight: 10 }}>Don't have an account yet ? <br />Register as  <Link to={"/b2bregistration"} className='accountText' style={{ cursor: 'pointer', fontFamily: 'Nunito' }}>Company</Link> or <Link to={"/customerregistration"} className='accountText' style={{ cursor: 'pointer', fontFamily: 'Nunito' }}>Customer</Link></span>
								</Grid>

							</Grid>
						</Grid>
						<Grid item xs={12} md={6} sx={{}} sm={12}>
							<Grid className='log-holder' container justifyContent="center" style={{ }}  >
								<Grid className='log-main' xs={12} md={6} position={'relative'} >
									{/* <BorderBox className="login" style={{width:"60%"}}> */}
										<Typography className='log-m-h'>Login</Typography>
										{/* <Grid position={'absolute'} width={'100%'} height={50} boxShadow={.5} justifyContent={'center'} top={0} display={{ md: 'none', xs: 'flex' }} alignItems={'center'} px={2}>
											<Avatar src={logo} variant="square" sx={{ width: 60, height: 30, cursor: 'pointer' }} onClick={() => navigate('/')}>

											</Avatar>
										</Grid> */}
										<form onSubmit={handleSubmit(onSubmit)}>

											<Grid className='log-con' container>
												<Grid item xs={12} md={6} style={{maxWidth:"95%", width:"100%"}}>
													<div style={{}}>

														<Box width={{ xs: "100%", md: '100%' }}>
															<InputField
																fieldLabel="Email Address"
																fieldName="email"
																control={control}
																error={errors.email}
																width={{ xs: "100%", md: '50%' }}
															/>
															<div style={{ paddingTop: 5 }}>
																<InputField
																	fieldLabel="Password"
																	fieldName="password"
																	control={control}
																	error={errors.password}
																	width={{ xs: "100%", md: '50%' }}
																	fieldType={"password"}
																/>
															</div>
														</Box>

													</div>
													<Box width={{ xs: "100%", md: '100%' }} display={"flex"} justifyContent="flex-end" paddingTop={"1px"} paddingBottom={1}>
														<Typography onClick={() => navigate("/forgotpassword")} style={{ fontFamily: 'Nunito', cursor: 'pointer', fontSize: 12, marginRight: 10, }}>FORGOT PASSWORD?</Typography>
													</Box>
													<Button type="submit" className="btn_login"
														sx={{ boxShadow: 2 }}
														variant="filled" style={{}}
													>
														<Typography className='buttonText'>Login</Typography>
													</Button>
												</Grid>
											</Grid>

										</form>
									{/* </BorderBox> */}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Layout>
		</div>

	)
}

export default Login
