import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import footerlogo from '../assets/images/citilux_logo.png'
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider'
import { useLocation, useNavigate } from 'react-router-dom'
import { SET_PREVIOUS_ROUTE } from '../Redux/constants/userConstants'
import { Stack } from '@mui/system';
import { Avatar, Grid } from '@mui/material'
import RegisterMenu from './RegisterMenu';
import Link from '@mui/material/Link';
import AboutUs from '../screens/AboutUs';
import facebook from '../assets/images/facebook.png'
import advicer from '../assets/images/advicer.png'
import {FaYoutube, FaTwitter, FaFacebook, FaLinkedin, FaEnvelope, FaInstagram} from 'react-icons/fa'

const Footer = () => {

  // const { user } = useSelector(state => state.auth)
  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const AboutUs = () => {
    setAnchorElNav(null);
    navigate('/aboutus')
  };
  const TermsConditions = () => {
    setAnchorElNav(null);
    navigate('/termsconditions')
  };
  const PrivacyPolicy = () => {
    setAnchorElNav(null);
    navigate('/privacypolicy')
  };
  const CustomerRegister = () => {
    setAnchorElNav(null);
    navigate('/customerregistration')
  };

  const CompanyRegister = () => {
    setAnchorElNav(null);
    navigate('/b2bregistration')
  };


  return (
    <div>
    <footer id="footer" style={{display: 'none'}}>
      <Grid className='padd-r-l'>
                <div class="circle-one"></div>
                <div class="circle-two"></div>

        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>

            <div className="logo">
              <img src={footerlogo} alt="footer-logo" />
            </div>

          </Grid>
          <Grid item md={4} xs={12}>

            <h3 className='column_title'>About</h3>
            <p>Singapore has become a tourist favourite, attracting people from all over the world.
            We providing luxury and comfort transport to our client to give them the best stay in Singapore. 
              <span className='readmore_link' onClick={AboutUs}>Read More</span>
              <div className='social-m'>
              <a className='facebook-i' href="https://www.facebook.com/limousine.maxicab.singapore/">
              {/* <svg width="9" height="16" viewBox="0 0 15 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 10V7.02344C10 5.67969 10.2969 5 12.3828 5H15V0H10.6328C5.28125 0 3.51562 2.45313 3.51562 6.66406V10H0V15H3.51562V30H10V15H14.4062L15 10H10Z" fill="white"/>
              </svg> */}
              <img src={facebook} alt="facebook" />
              </a>
              <a href="https://www.tripadvisor.com.sg/Attraction_Review-g294265-d17449220-Reviews-JM_TRAVEL_LIMOUSINE-Singapore.html">
              <img src={advicer} alt="trip_advicer" />
              </a>
              </div>
            </p>

          </Grid>
          <Grid item md={2} xs={12}>

            <h3 className='column_title'>Policies</h3>
            <p className='link' onClick={TermsConditions} >Terms and Conditions</p>
            <p className='link' onClick={PrivacyPolicy} >Privacy Policy</p>


          </Grid>
          <Grid item md={2} xs={12}>

            <h3 className='column_title'>Contact Us</h3>
            <p>+65 8833 2234</p>
            <a href='mailto:reservations@citilux.com.sg'>reservations@citilux.com.sg</a>


          </Grid>
          <Grid item md={2} xs={12}>

            <h3 className='column_title'>Registration</h3>
            <p className='link' onClick={CustomerRegister}>Customer Registration</p>
            <p className='link' onClick={CompanyRegister}>Company Registration</p>


          </Grid>
        </Grid>

      </Grid>
      {/* <div className="copy-right">
        <p>Copyright Citilux Limo 2024.All rights reserved.</p>
      </div> */}
    </footer>

    {/* new footer */}
    <footer id="footer" style={{}}>
      {/* <Container> */}

        <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <div className='footer-content'>
            <h3>CONTACT</h3>
            <div>
              <h4>Contact Info</h4>
              <ul>
                <li><span> - </span>Office: +65 6100-0328</li>
                <li><span> - </span>Mobile: +65 9128-6935</li>
                <li><span> - </span> Email: reservations@citilux.com.sg</li>
              </ul>
            </div>
            <div>
              <h4>Address</h4>
              <ul>
                <li><span> - </span> Citi-Lux Pte Ltd</li>
                <li><span> - </span> 1 Defu Lane 6</li>
                <li><span> - </span> Singapore 539365</li>
              </ul>
            </div>
            <div className='social-link'>
            <a href="mailto:reservations@citilux.com.sg" target="_blank"><FaEnvelope className='footer-icon' /></a>
            <a href="https://www.facebook.com/citiluxlimo/" target="_blank"><FaFacebook className='footer-icon' /></a>
            <a href="https://www.instagram.com/citiluxlimo/" target="_blank"><FaInstagram className='footer-icon' /></a>
            <a href="https://twitter.com/citiluxlimo" target="_blank"><FaTwitter className='footer-icon' /></a>
              {/* <a href="#"><FaYoutube className='footer-icon' /></a>
              <a href="#"><FaLinkedin className='footer-icon' /></a> */}
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12} className='footer-bg-container'>
          <div className='footer-bg'>
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=700&amp;hl=en&amp;q=1 Defu Lane 6, Singapore&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                <a href="https://embed-googlemap.com">google map embed html</a>
              </div>
            </div>
          </div>
        </Grid>
        </Grid>

      {/* </Container> */}
      <div className="copy-right">
        <p>&copy;Copyright 2024 CITI-LUX Pte Ltd | All rights reserved. </p>
      </div>
    </footer>
    </div>
  )

}

export default Footer

