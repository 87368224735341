import React, { useEffect ,useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, MenuItem } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import * as yup from "yup";
import DropdownInput from '../../components/DropdownInput';
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs'
import BorderBox from '../../components/BorderBox'
const B2BForm = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [country, setCountry] = useState(null)
    const [countrycompany, setCountryCompany] = useState(null)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const { registerSuccess, error } = useSelector(state => state.auth)

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const schema = yup.object({
        firstname: yup.string().required('First name is required').max(100, 'Maximum character exceeded'),
        lastname: yup.string().required('Last name is required').max(100, 'Maximum character exceeded'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required').max(30, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email is required').max(150),
        password: yup.string().required('Password is required').min(8, 'Password should contain atleast 8 characters').max(30, 'Maximum character exceeded'),
        confirm_password: yup.string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
        })
            .required("Confirm Password Required").max(30),
        user_type: yup.string().required('User type is required'),
    }).required();

    const { register, handleSubmit, control, formState: { errors },reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            company_name: '',
            company_phone: '',
            company_phone_code: '',
            company_email: '',
            company_address: '',
            company_tax: '',
            name:'',
            email:'',
            lastname:'',
            phone:'',
            mobile:'',
            password:''
        },
        mode: 'onChange'
    });

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }
        if (registerSuccess) {
            setOpen(true)
            reset()
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error, registerSuccess])

    const changePhone = (value) => {
        setCountry(value)
    }

    const changeBussinessPhone = (value) => {
        setCountryCompany(value)
    }

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("email", data.email);
        formData.append("name", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("mobile", country?.phonenumber ? country?.phonenumber : '');
        formData.append("phone", country?.phonenumber ? country?.phonenumber : '');
        formData.append("country_code", country?.callingCode ? country?.callingCode : '');
        formData.append("password", data.password);
        formData.append("company_name", data.company_name);
        formData.append("company_phone", countrycompany?.phonenumber ? countrycompany?.phonenumber : '');
        formData.append("company_phone_code", countrycompany?.callingCode ? countrycompany?.callingCode : '');
        formData.append("company_email", data.company_email);
        formData.append("company_address", data.company_address);
        formData.append("company_tax", data.company_tax);
        formData.append("user_type", data.user_type);
        formData.append("is_corporate", 2);
        dispatch(userRegister(formData))
    };
    return (
        <div className='regiter-bg'>

        <Grid container justifyContent="center"  >
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    User successfully registered
                </Alert>
            </Snackbar>

            {/* <Grid container spacing={2} className='reg-con-hold'> */}
					{/* <Grid item xs={6} md={6} sx={{ }} className='reg-text'>
                        <h3>Please <span className='reg-left'>Regsiter</span> Here To Create Your Account</h3>
                    </Grid> */}

            <Grid item xs={12} md={12} >
                {/* <h3 className='cus-reg-h'>Please <span>Register</span> Here To Create Your <br /> Account For Customers</h3> */}
                <h3 className='cus-reg-h'>Company Registration</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup >
                        <Grid className='reg-u-t' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '2%' }}>
                            <DropdownInput className='drp-u' fieldLabel="User Type" fieldName="user_type" placeholder="select" control={control} error={errors.user_type} width={260}>
                                <MenuItem style={{ fontSize: 12, fontFamily: 'Nunito' }} value='1'>Corporate</MenuItem>
                                <MenuItem style={{ fontSize: 12, fontFamily: 'Nunito' }} value='2'>Travel Agency</MenuItem>
                            </DropdownInput>
                        </Grid>
                        
                        <Grid className='reg-main' container spacing={2} paddingTop={5}>
                            <Grid className='reg-frm' xs={12} md={5} sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                                {/* <BorderBox title={"Basic Information"}> */}
                                <div className='reg-h-con'>
                                    <Typography className='reg-h'>Basic Information</Typography>
                                </div>
                                    <Grid spacing={0.5} container className='reg-cus-frm'>
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                fieldLabel="First Name"
                                                fieldName="firstname"
                                                control={control}
                                                error={errors.firstname}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                fieldLabel="Last Name"
                                                fieldName="lastname"
                                                control={control}
                                                error={errors.lastname}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                fieldLabel="Email Address"
                                                fieldName="email"
                                                control={control}
                                                error={errors.email}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <PhoneInputs
                                                fieldLabel="Phone Number"
                                                fieldName="phone"
                                                control={control}
                                                error={errors.phone}
                                                onChangePhone={changePhone}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                fieldLabel="Password"
                                                fieldName="password"
                                                control={control}
                                                error={errors.password}
                                                fieldType="password"
                                                placeholder='Password should contain atleast 8 characters'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                fieldLabel="Confirm Password"
                                                fieldName="confirm_password"
                                                control={control}
                                                error={errors.confirm_password}
                                                fieldType="password"
                                            />
                                        </Grid>
                                    </Grid>
                                {/* </BorderBox> */}
                            </Grid>

                            <Grid className='reg-com-con' xs={12} md={5} sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                                {/* <BorderBox title="Company Details"> */}
                                <div className='reg-com-h-con'>
                                    <Typography className='reg-com-h'>Customer Details</Typography>
                                </div>
                                    <Grid spacing={0.5} container className='reg-cus-frm'>
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                fieldLabel="Company Name"
                                                fieldName="company_name"
                                                control={control}
                                                error={errors.company_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                fieldLabel="Company Email Address"
                                                fieldName="company_email"
                                                control={control}
                                                error={errors.company_email}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <PhoneInputs
                                                fieldLabel="Phone Number"
                                                fieldName="company_phone"
                                                control={control}
                                                error={errors.company_phone}
                                                onChangePhone={changeBussinessPhone}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputField
                                                fieldLabel="Tax No."
                                                fieldName="company_tax"
                                                control={control}
                                                error={errors.company_tax}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <InputField
                                                fieldLabel="Company Address"
                                                fieldName="company_address"
                                                control={control}
                                                error={errors.company_address}
                                                multiline="multiline"
                                            />
                                        </Grid>
                                    </Grid>
                                {/* </BorderBox> */}
                            </Grid>
                            <Grid xs={12} md={12} sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', marginTop: 2 }} >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: '5%' }}>
                                    <Button type="submit" variant="filled" className="primary-btn" style={{  }}>
                                        <Typography className='buttonText'>Register</Typography></Button>
                                </div>
                            </Grid>
                        </Grid>
                    </FormGroup>
                </form>
            </Grid>
            {/* </Grid> */}
        </Grid>
        </div>

    )
}

export default B2BForm
