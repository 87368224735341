import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import about from '../../assets/images/about-two.jpg'
import aboutus from '../../assets/images/about-one.jpg'
import safety_first from '../../assets/images/safety-first.png'
import reasonable_rates from '../../assets/images/resonable-rate.png'
import largest_fleet from '../../assets/images/largest-fleet.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';

const AboutUsContent = () => {


    return (

        <div>
            <div className="about_sec1" >

               

                        <div className="leftside_textholder">
                            <div className='abt-c'>
                                <Grid container spacing={2} className='about-us-hol'>
                                    <Grid item md={6} xs={12}>
                                        <h3 className="section_heading">Company Profile</h3>
                                        <p>Citi-Lux, incorporated since 2010, is backed by a wealth of experience to service corporate guest, VIP and leisure travellers. Citi-Lux is set up to serve a growing base of corporate customers, ferrying thousands of customers from leading international companies and VIPs to their important engagements, airport transfers and chauffeur driven tours. We set our objectives to offer market-leading chauffeur services in Singapore, delivering outstanding service and improving chauffeuring standards in Singapore. We aspire to be an exclusive 5 star luxury transportation company dedicated to service discerning travellers and guests with style and comfort. We also work with international ground transportation companies to provide services worldwide, including US, Europe and other Asia countries.</p>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <img src={aboutus} />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} className='about-us-hol second-about'>

                                    <Grid item md={6} xs={12}>
                                        <img src={about} />
                                    </Grid>

                                    <Grid item md={6} xs={12}>
                                        <h3 className="section_heading">Commitment to our Customers</h3>
                                        <div className='abt-c'>
                                        <p>All our cars are immaculately maintained to the highest safety standards. Our personalised services also ensure that the customers’ needs and requirements are promptly attended to. Customer satisfaction is always our main priority, and we achieve this by providing Speedy Quality Services (SQS – we emphasise on our speed, quality and services). Customer will also gain online access to check on their past and future bookings and invoice status, including making new reservations and amendments. Wet towel and bottled water are also provided.</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                           

                        </div>

                

            </div>

            <div className="about_sec2">

                {/* <Grid container spacing={2} className="container_topspace">

                    <Grid item md={6} xs={12}>
                        <div className="leftside_imgholder">

                            <img src={aboutus_img2} />

                        </div>

                    </Grid>

                    <Grid item md={6} xs={12}>

                        <div className="rightside_textholder">

                            <h3 className="section_heading">Our Fleet</h3>

                            <p>The largest fleet of late model executive and luxury class vehicles in Singapore. Every JMLimousine.com vehicle is a late-model vehicle equipped with the newest in executive convenience appointments, from refined styling to sophisticated computer systems. All vehicles are tracked with continuous location updates for the customer.</p>
                            <ul className='service_point'>
                                <li> Professionally Trained Chauffeurs</li>
                                <li>24/7 Full-Service Transportation</li>
                                <li>Global network of Transportation Partners</li>
                            </ul>
                        </div>

                    </Grid>

                </Grid> */}

            </div>
            <div className="about_sec3">

                {/* <Grid container spacing={2} className="container_topspace">



                    <Grid item md={6} xs={12}>

                        <div className="rightside_textholder">

                            <h3 className="section_heading">Quality Control</h3>

                            <p>A stringent quality control program to monitor and enforce compliance and uniformity. JMLimousine drivers are seasoned and dedicated professionals. Every chauffeur initially receives formal training prior to acceptance as a Franchisee or as an employee and then attends regularly scheduled refresher classes. Primary focus is on customer service and communication skills, passenger safety and defensive driving.</p>

                        </div>

                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className="leftside_imgholder">

                            <img src={aboutus_img3} />

                        </div>

                    </Grid>

                </Grid> */}

            </div>
            <div className="about_sec4 ">
                {/* <h3 className="section_heading">Benefits of Hiring Citilux Limo</h3> */}

                {/* <Grid container spacing={2} className="">



                    <Grid item md={4} xs={12}>

                        <div className="box_content">
                            <div className='icon_img'><img src={safety_first} /></div>

                            <h3 className="box_heading">Safety First</h3>

                            <p>Experienced staff and professionally trained chauffeurs.</p>

                        </div>

                    </Grid>
                    <Grid item md={4} xs={12}>

                        <div className="box_content">
                        <div className='icon_img'><img src={reasonable_rates} /></div>
                            <h3 className="box_heading">Resonable Rates</h3>

                            <p>We can offer you the right vehicle at the right price to fit your budget.</p>

                        </div>

                    </Grid>
                    <Grid item md={4} xs={12}>

                        <div className="box_content">
                        <div className='icon_img'><img src={largest_fleet} /></div>
                            <h3 className="box_heading">Largest Fleet</h3>

                            <p>We offer an extensive fleet of vehicles including sedans, limousines and coach buses.</p>

                        </div>

                    </Grid>


                </Grid> */}

            </div>

        </div>

    )
}

export default AboutUsContent
