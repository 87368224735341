import React from 'react'
import Banner from '../../components/Banner'
import Header from '../../components/Header';
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import B2BForm from './B2BForm';
import { Grid } from '@mui/material';

const B2BRegistration = () => {

    return (
        <div className='register-holder'>
            <Layout head={""}>         
                <B2BForm/>            
            </Layout>
        </div>
    )
}

export default B2BRegistration
