import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import CustomerForm from './CustomerForm';

const CustomerRegistration = () => {

    return (
        <div className='customer-reg'>
            <Layout head={""}>
                <CustomerForm/>   
            </Layout>
        </div>
    )
}

export default CustomerRegistration
