import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet,Image, Font } from "@react-pdf/renderer";
import Logo from '../../assets/images/citilux_logo.png'
import './style.scss'
import moment from 'moment'
import { capitalize } from 'lodash';

Font.register({
    family: "Nunito",
    src: "../../assets/fonts/nunito-v25-latin-200.ttf",
})

const styles = StyleSheet.create({
    
    page: {
        backgroundColor: '#fff',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 90,
        height: 90,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    titleContainer: {
        marginTop: 10,
        marginBottom: 0
    },
    reportTitle: {
        color: '#333333',
        fontSize: 18,
        textAlign: 'center',
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        fontSize: 12,
        justifyContent:'space-between',
        
    },
    semiStyle:
    {
        flexDirection: 'row',
        fontSize: 12,
        fontWeight:'bold',
        justifyContent:'space-between'
    },

    fromHead:
    {
        flexDirection: 'row',
        fontSize: 18,
        fontWeight: 'bold',
        justifyContent:'space-between',
        marginTop:10,
        marginBottom:1,
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderBottomColor:'#F7F6F6',
        borderBottomWidth:2,
    },
    container: {
        flexDirection: 'row',
        color: '#333333',
        backgroundColor:'#F7F6F6',
        alignItems: 'center',
        height: 24,    
        fontStyle: 'bold',
        flexGrow: 1
    },
    description: {
        width: '35%',
        textAlign: 'start',
        opacity:0.5
    },
    amount: {
        width: '35%',
        textAlign: 'right',
        opacity:0.5
    },
    type: {
        width: '30%',
        textAlign: 'center',
        opacity:0.5
    },
    totalrow: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    footerContainer: {
        marginTop: 20,
    },
    footerTitle: {
        fontSize: 12,
        textAlign: 'center',
        opacity:0.5,
        letterSpacing:0.85
    },
    tabamount: {
        width: '35%',
        textAlign: 'right',
        fontSize: 12,    
    },
    tabamounts: {
        textAlign: 'right',
        fontSize: 12,
        
    },
    tabamountlast: {
        textAlign: 'right',
        fontSize: 12,
        borderBottomColor:'#F7F6F6',
        borderBottomWidth:2,    
    },
    tabtype: {
        width: '30%',
        textAlign: 'center',
        fontSize: 12,    
    },
    totalamount: {
        textAlign: 'right',
        fontSize: 16,
        fontWeight:'bold',
        marginTop:25,
        
    },
    redamount: {
        color:'#FF0000',
        fontSize: 16,
        fontWeight:'bold',
    },
    paidamount:{
        color:'#539E00',
        fontSize: 15,
        fontWeight:'bold',
        textAlign:'start',
    },
    paymentMethod:{
        textAlign:'start',
        fontSize: 15,   
    },
    payment:{
        fontSize: 16,   
        fontWeight:'bold' 
    },
    bottomStyle:{
        fontSize:10,
        textAlign: 'center',
        color:'#333333',
        marginBottom:8,
        letterSpacing:0.75,
        fontWeight:'bold' 
    },
    bottom:{
        fontSize:12,
        letterSpacing:0.75,
        fontWeight:'bold' 
    },

    texts:{
        fontSize:12,
    },
    headtexts:{
        fontSize:16,
        fontWeight: 'bold',
        //fontFamily: 'Nunito'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
      },
    
});

const PDFFile = ({trips}) => {
    
    

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image style={styles.logo} src={Logo} /> 
                <h1>Text here</h1>
                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>Payment Receipt</Text>
                </View>
                <View style={styles.invoiceDateContainer}>
                    {/* <Text >Invoice No. </Text> */}
                    <Text></Text>
                    <Text style={styles?.texts}> {moment(trips?.row?.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</Text>   
                </View >
                <View style={styles.fromHead}>
                    <Text style={styles?.headtexts}>From</Text>
                    <Text style={styles?.headtexts}>Issued To</Text>
                </View >
                <View style={styles.semiStyle}>
                    <Text style={styles?.texts}>Prime Aces Limousine Service</Text>
                    <Text style={styles?.texts}>{trips?.row?.name}</Text>   
                </View >
                <View style={styles.invoiceDateContainer}>
                    <Text style={styles?.texts}>65 Chulia Street OCBC Centre,</Text>
                    <Text >{trips?.row?.email}</Text>   
                </View >
                <View style={styles.invoiceDateContainer}>
                    <Text style={styles?.texts}>Singapore 049513</Text>
                    <Text >{trips?.row?.mobile}</Text>   
                </View >
                <View style={styles.tableContainer}>
                    <View style={styles.container}>
                        <Text style={styles.description}>SERVICE TYPE</Text>
                        <Text style={styles.description}>VEHICLE TYPE</Text>
                        <Text style={styles.description}>AMOUNT</Text>
                    </View>
                    
                    <View style={styles.totalrow}>
                        <Text style={styles.description}>{trips?.row?.arrival}</Text>
                        <Text style={styles.description}>{ trips?.row?.type === "PAL" ? trips.row.vehicletype.vehicle_type : trips?.row?.transportName }</Text>
                        <Text style={styles.description}>S$ {parseFloat(trips?.row?.pricebreakups?.total_price).toFixed(2)}</Text>
                    </View>
                    
                </View>
                {/* <Text style={styles.tabamounts}>Markup Price  S$ {trips?.row?.pricebreakups?.agentMarkup}</Text>
                <Text style={styles.tabamounts}>Extra Charges  S$ {trips?.row?.pricebreakups?.extraCharges}</Text>
                <Text style={styles.tabamounts}>Toll  S$ {trips?.row?.pricebreakups?.toll}</Text>
                <Text style={styles.tabamountlast}>Tax  S$ {trips?.row?.pricebreakups?.tax}</Text>
                 */}
                <Text><Text style={styles.totalamount}>Total </Text><Text style={styles.redamount}>S$ {parseFloat(trips?.row?.pricebreakups?.total_price).toFixed(2)}</Text></Text>
                
                    <Text style={styles.paidamount}>Amount Paid  S${parseFloat(trips?.row?.pricebreakups?.total_price).toFixed(2)}</Text>
                    <Text><Text style={styles.paymentMethod}>Payment Method   </Text><Text style={styles.payment}>{capitalize(trips.row.payment_type)}</Text></Text>
                <View style={styles.footerContainer}>
                    <Text style={styles.footerTitle}>Thank You for travelling with Prime Aces Limousine! Below please find your confirmation. 
                    If any of the information appears to be incorrect, please contact our office immediately to correct it.</Text>
                </View>
                <View style={styles.pageNumber}>
                    <Text><Text style={styles.bottomStyle}>Phone: </Text><Text >(+65) 6100-5959 </Text></Text>
                    <Text><Text style={styles.bottomStyle}>Email: </Text><Text >Booking@primeaceslimousine.sg</Text></Text>
                    <Text><Text style={styles.bottomStyle}>Address: </Text><Text>65 Chulia Street OCBC Centre, Singapore 049513</Text></Text>
                </View>
            </Page>
        </Document>
    );
}

export default PDFFile;